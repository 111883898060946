@import '../../styles/shared.scss';

.modal-outer-container.coming-soon-modal .modal-container .modal-inner-container .modal-content.noPadding {
  @extend %flex-center-vertical;
  padding: 10%;
  gap: 48px;

  .headlines {
    @extend %flex-center-vertical;

    .shop-gif {
      height: 80px;
    }

    h1 {
      font-size: 40px;
      font-weight: 700;
      color: $black;
      margin: 0;
      text-align: center;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      color: $dark;
      margin: 0;
      text-align: center;
    }
  }

  .actions {
    @extend %flex-row;
    gap: 12px;

    .primary {
      @extend %btn-darkest-shop;
    }

    .secondary {
      @extend %btn-outline-shop;
    }
  }
}
