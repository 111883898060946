@import '../../styles/shared.scss';

.collection-action-items-container {
  @extend %flex-col;
  background: white;
  box-shadow: $shadow-dark;
  border-radius: 4px;
  position: absolute;
  bottom: 100%;
  font-family: $font-body;
  right: 20px;
  z-index: 100;
  min-width: 130px;
  padding: 6px 0;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  max-height: 250px;
  overflow: scroll;

  .item {
    @extend %flex-row-middle;
    @extend %btn-basic;
    color: $dark;
    font-size: 14px;
    white-space: nowrap;
    padding: 4px 12px;
    font-weight: bold;

    &.loading {
      cursor: default;
      font-weight: normal;
      color: $medium;

      &:hover {
        opacity: 1;
      }
    }

    .loader {
      margin-right: 6px;
      transform: translateY(-3px);
      font-size: 10px;
      max-width: 12px;
      max-height: 14px;
    }

    >svg {
      margin-right: 6px;
      transform: translateY(-0.5px);
      font-size: 10px;
      width: 12px;
      max-width: 12px;
      min-width: 12px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
}
